import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import Select from 'react-select';
import ListaProductos from './ListaProductos.js';

import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
let base_url = BASE_URL;
// let token = ACCESS_TOKEN;
let token = localStorage.getItem('token');

/*
{
    "nombre": "Cerveza paceña",
    "precio_venta": 50.0,
    "comision": 20.0,
    "es_servicio": false
}
*/

function Producto() {

    const [nombre, setNombre] = useState('');
    const [precioVenta, setPrecioVenta] = useState(0);
    const [comision, setComision] = useState(0);
    const [esServicio, setEsServicio] = useState(false);

    const [reloadLista, setReloadLista] = useState(false);

    useEffect(() => {
        // La primera vez que se renderiza, se establece reloadLista en true para cargar ListaDamas
        if (reloadLista) {
            setReloadLista(false); // Establecer de nuevo en false para evitar un bucle infinito
        }
    }, [reloadLista]); // Este efecto se ejecuta cada vez que reloadLista cambia

    const registrarProducto = () => {
        console.log("Nombre: ", nombre);
        console.log("Precio de Venta: ", precioVenta);
        console.log("Comision: ", comision);
        console.log("Es Servicio: ", esServicio);

        console.log({
            nombre: nombre,
            precio_venta: precioVenta,
            comision: comision,
            es_servicio: esServicio
        });

        fetch(base_url+"/api/products", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                nombre: nombre,
                precio_venta: precioVenta,
                comision: comision,
                es_servicio: esServicio == "true"
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            document.querySelector('#closeModalFormNuevoProducto').click();
            setReloadLista(true);
        })
        .catch(error => {
            console.error(error);
        });
    }

	return (
		<div>
			<h1 className="page-header">Gestion de Productos <small>Agregue, modifique y edite</small></h1>
			<div className="row">
                <div className="col-md-12">
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-3">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">Lista Productos</h4>
                        </div>
                        <div className="panel-body">
                            {!reloadLista && <ListaProductos />}
                        </div>
                        <div className="hljs-wrapper text-center">
                            <button className="btn btn-primary m-3" 
                                data-bs-toggle="modal" data-bs-target="#modalFormNuevoProducto"
                            >Nuevo Producto</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalFormNuevoProducto">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">REGISTRO DE PRODUCTO</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id='closeModalFormNuevoProducto'></button>
						</div>
						<div className="modal-body">
							
                            <div className="row">

								<div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input className="form-control" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Precio de Venta</label>
                                        <input className="form-control" type="number" value={precioVenta} onChange={(e) => setPrecioVenta(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Comision</label>
                                        <input className="form-control" type="number" value={comision} onChange={(e) => setComision(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Es Servicio</label>
                                        <select className="form-select" value={esServicio} onChange={(e) => setEsServicio(e.target.value)}>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>


							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-success btn-lg" onClick={registrarProducto}>Registrar</button>
							<button type="button" className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>



		</div>
	)
}

export default Producto;