import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Highlight from 'react-highlight';


import Swal from 'sweetalert2'

import withReactContent from 'sweetalert2-react-content'

import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
let base_url = BASE_URL;
let token = localStorage.getItem('token');


function Caja() {
    const [monto_apertura, setMontoApertura] = useState(0);

    const [arrayListaVentas, setArrayListaVentas] = useState([]);
	

    //set monto_apertura value = 0
    const formularioAbrirCaja = () => {
        setMontoApertura(0);
        document.getElementById('monto_apertura').value = "";
    }


    //{"monto_apertura": 150}
    const abrirCaja = () => {

        //if monto_apertura is string converto to number

        let data = JSON.stringify({
            "monto_apertura": parseInt(monto_apertura)
        });

        //monto_apertura restricciones de ese campo: requerido, numerico, mayor o igual a 0

        if(monto_apertura === '' || monto_apertura === 0) {
            Swal.fire({
                title: 'Caja',
                text: 'El monto de apertura es requerido',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            return;
        }

        fetch(base_url + '/api/cashcounts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: data
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            document.getElementById('cerrarModalFormularioAbrirCaja').click();
            if(data.message == 'Caja abierta correctamente') {
                Swal.fire({
                    title: 'Caja',
                    text: 'Caja abierta',
                    icon: 'success',
                    confirmButtonText: 'Imprimir Comprobante'
                }).then((result) => {
                    if(result.isConfirmed) {
                        printApertura(data);
                    }
                });
            }
            else{
                Swal.fire({
                    title: 'Caja',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    const printApertura = (data) => {
		// Imprimir Cierre de Caja
        /*
            {
                "data": {
                    "id": 1,
                    "usuario": "Christian Gómez",
                    "monto_apertura": 100,
                    "monto_cierre": null,
                    "monto_cierre_efectivo": null,
                    "monto_cierre_tarjeta_qr": null,
                    "monto_cierre_otros": null,
                    "observaciones": null,
                    "abierto_en": "20/05/2024 05:27:58",
                    "cerrado_en": "20/05/2024 05:27:58",
                    "recogido_en": null,
                    "recogido_por": null
                },
                "status": "success"
            }
        */
		let contenido = `
		<!DOCTYPE html>
		<html lang="es">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
			<title>Apertura de Caja</title>
			<style>
				* {
					font-family: Arial, sans-serif;
				}
				.container {
					width: 100%;
					max-width: 800px;
					margin: 0 auto;
					padding: 10px;
				}
				.text-center {
					text-align: center;
				}
				.text-right {
					text-align: right;
				}
				.text-left {
					text-align: left;
				}
				.bold {
					font-weight: bold;
				}
				.italic {
					font-style: italic;
				}
				.underline {
					text-decoration: underline;
				}
			</style>
		</head>
		<body>
			<div class="container">
				<h2 class="text-center">Apertura de Caja</h2>
				<span class="text-left"><b>Usuario:</b> ${data.data.usuario}</span><br>
                <span class="text-left"><b>Monto de Apertura:</b> ${data.data.monto_apertura}</span><br>
                <span class="text-left"><b>Monto de Cierre:</b> ${data.data.monto_cierre}</span><br>
                <span class="text-left"><b>Monto de Cierre Efectivo:</b> ${data.data.monto_cierre_efectivo}</span><br>
                <span class="text-left"><b>Monto de Cierre Tarjeta QR:</b> ${data.data.monto_cierre_tarjeta_qr}</span><br>
                <span class="text-left"><b>Monto de Cierre Otros:</b> ${data.data.monto_cierre_otros}</span><br>
                <span class="text-left"><b>Abierto en:</b> ${data.data.abierto_en}</span><br>
                <span class="text-left"><b>Cerrado en:</b> ${data.data.cerrado_en}</span><br>
                <span class="text-left"><b>Recogido en:</b> ${data.data.recogido_en}</span><br>
                <span class="text-left"><b>Recogido por:</b> ${data.data.recogido_por}</span><br>
			</div>
		</body>
		</html>
		`;
		let ventana = window.open('', 'PRINT', 'height=400,width=600');
		ventana.document.write(contenido);
		ventana.document.close();
		ventana.print();
		ventana.close();
	}

    /*cerrar caja get API /api/cashcounts/cerraractual
    {
        "data": {
            "monto_apertura": "100.00",
            "monto_cierre": 200,
            "monto_cierre_efectivo": 200,
            "monto_cierre_tarjeta_qr": 0,
            "monto_cierre_otros": 0,
            "observaciones": null,
            "abierto_en": "2024-04-27T02:46:15.000000Z",
            "cerrado_en": {
                "date": "2024-04-27 02:46:27.002814",
                "timezone_type": 3,
                "timezone": "UTC"
            },
            "recogido_en": null,
            "recogido_por": null
        }
    }
    */
    const confirmarCerrarCaja = () => {
        Swal.fire({
            title: 'Caja',
            text: '¿Está seguro de cerrar la caja?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then((result) => {
            if(result.isConfirmed) {
                cerrarCaja();
            }
        });
    }

    const cerrarCaja = () => {
        console.log(token);
        fetch(base_url + '/api/cashcounts/cerraractual', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            //data.status
            //data.message
            Swal.fire({
                title: 'Caja',
                text: data.message,
                icon: data.status,
                confirmButtonText: 'Imprimir Comprobante'
            }).then((result) => {
                if(result.isConfirmed) {
                    printCierre(data);
                }
            });
        })
        .catch(error => {
            console.error(error);
        });
    }

    const printCierre = (data) => {
		// Imprimir Cierre de Caja
        /*
            {
            "data": {
                "id": 1,
                "usuario": "Christian Gómez",
                "monto_apertura": "100.00",
                "monto_cierre": "100.00",
                "monto_cierre_efectivo": "100.00",
                "monto_cierre_tarjeta_qr": 0,
                "monto_cierre_otros": 0,
                "observaciones": null,
                "abierto_en": "20/05/2024 05:27:58",
                "cerrado_en": "20/05/2024 05:35:07",
                "recogido_en": null,
                "recogido_por": null
            },
            "status": "success"
        }
        */
		let contenido = `
		<!DOCTYPE html>
		<html lang="es">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
			<title>Cierre de Caja</title>
			<style>
				* {
					font-family: Arial, sans-serif;
				}
				.container {
					width: 100%;
					max-width: 800px;
					margin: 0 auto;
					padding: 10px;
				}
				.text-center {
					text-align: center;
				}
				.text-right {
					text-align: right;
				}
				.text-left {
					text-align: left;
				}
				.bold {
					font-weight: bold;
				}
				.italic {
					font-style: italic;
				}
				.underline {
					text-decoration: underline;
				}
			</style>
		</head>
		<body>
			<div class="container">
				<h2 class="text-center">Cierre de Caja</h2>
				<span class="text-left"><b>Usuario:</b> ${data.data.usuario}</span><br>
                <span class="text-left"><b>Monto de Apertura:</b> ${data.data.monto_apertura}</span><br>
                <span class="text-left"><b>Monto de Cierre:</b> ${data.data.monto_cierre}</span><br>
                <span class="text-left"><b>Monto de Cierre Efectivo:</b> ${data.data.monto_cierre_efectivo}</span><br>
                <span class="text-left"><b>Monto de Cierre Tarjeta QR:</b> ${data.data.monto_cierre_tarjeta_qr}</span><br>
                <span class="text-left"><b>Monto de Cierre Otros:</b> ${data.data.monto_cierre_otros}</span><br>
                <span class="text-left"><b>Abierto en:</b> ${data.data.abierto_en}</span><br>
                <span class="text-left"><b>Cerrado en:</b> ${data.data.cerrado_en.date}</span><br>
                <span class="text-left"><b>Recogido en:</b> ${data.data.recogido_en}</span><br>
                <span class="text-left"><b>Recogido por:</b> ${data.data.recogido_por}</span><br>
			</div>
		</body>
		</html>
		`;
		let ventana = window.open('', 'PRINT', 'height=400,width=600');
		ventana.document.write(contenido);
		ventana.document.close();
		ventana.print();
		ventana.close();
	}


    const modalReporteVentas = () => {
        // Swal.fire({
        //     title: 'Reporte de Ventas',
        //     text: 'En construcción',
        //     icon: 'info',
        //     confirmButtonText: 'Ok'
        // });
        fetch(base_url + '/api/sales/currentcashcount', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            /*
            'id'
            'producto'
            'dama'
            'cantidad'
            'precio'
            'comision'
            'metodo_pago'
            */
            console.log(data);
            setArrayListaVentas(data.data);
        })
        .catch(error => {
            console.error(error);
        });
    }
    
    return (
        <div>
            <div className="row">
                <div className="col-md-6 text-center">
                    <button className='btn btn-purple' data-bs-toggle="modal" data-bs-target="#modalReporteVentas" onClick={modalReporteVentas}>
                        <i className='fa fa-file'></i> <br/>Reporte de Ventas
                    </button>
                </div>
                <div className="col-md-6 text-center">
                    <div className='text-center'>
                        <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#modalFormularioAbrirCaja" onClick={formularioAbrirCaja}>
                            <i className='fa fa-check'></i> <br/>Abrir Caja
                        </button>
                        <button className='btn btn-danger' onClick={confirmarCerrarCaja}>
                            <i className='fa fa-times'></i> <br/>Cerrar Caja
                        </button>
                    </div>
                </div>
            </div>
			

			<div className="modal fade" id="modalFormularioAbrirCaja">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">APERTURA DE CAJA</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id="cerrarModalFormularioAbrirCaja"></button>
						</div>
						<div className="modal-body">
							
                            <div className="form-group">
                                <label htmlFor="monto_apertura">Monto de Apertura</label>
                                <input type="number" className="form-control" id="monto_apertura" name="monto_apertura" value={monto_apertura} onChange={(e) => setMontoApertura(e.target.value)} />
                            </div>
								
						</div>
						<div className="modal-footer">
							<button className="btn btn-danger btn-lg" onClick={abrirCaja}>Abrir Caja</button>
							<button className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>


			<div className="modal fade" id="modalReporteVentas">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">REPORTE DE VENTAS</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id="cerrarModalReporteVentas"></button>
						</div>
						<div className="modal-body">
							
                            <table className="table table-bordered table-striped table-hover" style={{border: '1px solid #77878E'}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Producto</th>
                                        <th>Dama</th>
                                        <th>Cantidad</th>
                                        <th>Precio</th>
                                        <th>Comisión</th>
                                        <th>Método de Pago</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        arrayListaVentas.length === 0 ? (
                                            <tr>
                                                <td colSpan="9">No hay ventas registradas</td>
                                            </tr>
                                        ) : (
                                            arrayListaVentas.map((venta, index) => (
                                                <tr key={index}>
                                                    <td>{venta.id}</td>
                                                    <td>{venta.fecha}</td>
                                                    <td>{venta.hora}</td>
                                                    <td>{venta.producto}</td>
                                                    <td>{venta.dama}</td>
                                                    <td>{venta.cantidad}</td>
                                                    <td>{venta.precio}</td>
                                                    <td>{venta.comision}</td>
                                                    <td>{venta.metodo_pago}</td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </table>

							
						</div>
						<div className="modal-footer">
							<button className="btn btn-danger btn-lg" data-bs-dismiss="modal">Cerrar</button>
						</div>
					</div>
				</div>
			</div>
			


        </div>

		
    );
}


export default Caja;