import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useRoutes } from "react-router-dom";
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import AppRoute from './config/app-route.jsx';
import Login from './pages/login/login.js';

import BASE_URL from './config.js';
import ACCESS_TOKEN from './token.js';


// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';
//import boostrap modals

let base_url = BASE_URL;
let token = localStorage.getItem('token')

const container = document.getElementById('root');
const root = createRoot(container);
function App() {
	/*if(window.location.pathname === '/login') {
		return <Login />;
	}
	else{
		return (
			<BrowserRouter>
				{useRoutes(AppRoute)}
			</BrowserRouter>
		);
	}*/
	let element = useRoutes(AppRoute);
	
	return element;
}

if(token === '') {
	root.render(
		<Login />
	);
}
else {
	root.render(
		<Router>
			<App />
		</Router>
	);
}

// root.render(
//    <BrowserRouter>
//    	<App />
//    </BrowserRouter>
// );
