import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import Select from 'react-select';
import ListaCajas from './ListaCajas.js';

import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
let base_url = BASE_URL;
let token = localStorage.getItem('token');



function AbiertasCajas() {

    

	return (
		<div>
			<h1 className="page-header">Lista de Cajas Abiertas <small>Gestion de cajas, recojo de dinero</small></h1>
			<div className="row">
                <div className="col-md-12">
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-3">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">Lista Cajas</h4>
                        </div>
                        <div className="panel-body">
                            {/* {!reloadLista && <ListaCajas />} */}
                            <ListaCajas />
                        </div>
                    </div>
                </div>
            </div>




		</div>
	)
}

export default AbiertasCajas;