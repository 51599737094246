import React, { useState, useEffect } from 'react';
import BASE_URL from '../../config.js';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { HeartSwitch } from '@anatoliygatt/heart-switch';

let base_url = BASE_URL;
//let token = ACCESS_TOKEN;
let token = localStorage.getItem('token');
let rol_user = localStorage.getItem('rol_user');

const optionsPaises = [
    { value: '+591', label: 'Bolivia (+591)' },
    { value: '+54', label: 'Argentina (+54)' },
    { value: '+55', label: 'Brasil (+55)' },
    { value: '+56', label: 'Chile (+56)' },
    { value: '+57', label: 'Colombia (+57)' },
    { value: '+502', label: 'Guatemala (+502)' },
    { value: '+503', label: 'El Salvador (+503)' },
    { value: '+504', label: 'Honduras (+504)' },
    { value: '+505', label: 'Nicaragua (+505)' },
    { value: '+506', label: 'Costa Rica (+506)' },
    { value: '+507', label: 'Panamá (+507)' },
    { value: '+51', label: 'Perú (+51)' },
    { value: '+52', label: 'México (+52)' },
    { value: '+598', label: 'Uruguay (+598)' },
    { value: '+595', label: 'Paraguay (+595)' },
    { value: '+593', label: 'Ecuador (+593)' },
    { value: '+58', label: 'Venezuela (+58)' },
];

function ListaDamas() {
    const [damas, setDamas] = useState([]);
    const [loading, setLoading] = useState(true);


    //variables para editar dama
    const [idDama, setIdDama] = useState(0);
    const [alias, setAlias] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [procedencia, setProcedencia] = useState('');
    const [codigoPaisTel1, setCodigoPaisTel1] = useState('');
    const [telefono1, setTelefono1] = useState('');
    const [codigoPaisTel2, setCodigoPaisTel2] = useState('');
    const [telefono2, setTelefono2] = useState('');
    const [notas, setNotas] = useState('');

    const [reloadDamas, setReloadDamas] = useState(false);

    useEffect(() => {
        fetch(base_url+"/api/ladies", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            setDamas(data.data);
            console.log(data.data);
            setLoading(false);
            setReloadDamas(false);
        })
    }, [reloadDamas]);


    const editarDama = () => {
        document.getElementById('btnEditarDama').disabled = true;
        //metodo PUT, 
        // /api/ladies/{lady}
        /*
        'alias',
        'numero_documento', 
        'nombres',
        'apellidos', 
        'fecha_nacimiento', 
        'procedencia', 
        'telefono1',
        'telefono2',
        'notas',
        */

        let data = {
            "alias": alias,
            "numero_documento": numeroDocumento,
            "nombres": nombres,
            "apellidos": apellidos,
            "fecha_nacimiento": fechaNacimiento,
            "procedencia": procedencia,
            "telefono1": telefono1,
            "telefono2": telefono2,
            "notas": notas
        };

        fetch(base_url+"/api/ladies/"+idDama, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if (data.status == 'success') {
                document.getElementById('closeModalFormEditarDama').click();
                window.location.reload();
            }
            else{
                document.getElementById('btnEditarDama').disabled = false;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            document.getElementById('btnEditarDama').disabled = false;
        });


    }

    //metodo get, /api/ladies/{lady}/products
    /*
    retorna array de objetos: {
        'product_id'
        'producto'
        'lady_id'
        'precio_venta'
        'comision'
        'activo'
    }
    */
   
    const [dataProductosDamaxId, setDataProductosDamaxId] = useState([]);
    const [loadingProductosDamaxId, setLoadingProductosDamaxId] = useState(true);
    const [aliasDama, setAliasDama] = useState('');

    const verProductosDamaxId = (idDama) => {
        console.log(base_url+"/api/ladies/"+idDama+"/products");
        setIdDama(idDama);
        setLoadingProductosDamaxId(true);
        fetch(base_url+"/api/ladies/"+idDama+"/products", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setDataProductosDamaxId(data.data);
            setLoadingProductosDamaxId(false);
        })
    }


    const [producto_id, setProductoId] = useState('');
    const [precio_venta, setPrecioVenta] = useState(0);
    const [comision, setComision] = useState(0);
    const [dataListaProductos, setDataListaProductos] = useState([]);

    useEffect(() => {
        fetch(base_url+"/api/products", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            setDataListaProductos(data.data);
            console.log("ARRAY DE PRODUCTOS:");
            console.log(data);
            setLoading(false);
        });
    }, []);

    const modificarProductoDama = () => {
        /*
        metodo PUT,  /api/ladies/{lady}/products/{product}    con json: {
       'precio_venta', 
        'comision'
        }
        */
        if(producto_id != '' && precio_venta != 0 && comision != 0){
            let data = {
                "precio_venta": precio_venta,
                "comision": comision
            };
            fetch(base_url+"/api/ladies/"+idDama+"/products/"+producto_id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                /*
                {
                    "status": "success",
                    "message": "Producto actualizado correctamente"
                }
                */
                Swal.fire({
                    text: data.message,
                    icon: data.status,
                    confirmButtonText: 'Aceptar'
                });
                verProductosDamaxId(idDama);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
    }

    /*
    para togglear la disponibilidad del producto: metodo POST: /api/ladies/{lady}/products/{product}/toggle
    */
    const toggleProducto = (product_id) => {
        //console.log(base_url+"/api/ladies/"+idDama+"/products/"+product_id+"/toggle");
        fetch(base_url+"/api/ladies/"+idDama+"/products/"+product_id+"/toggle", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            /*
            {
                "status": "success",
                "message": "Producto actualizado correctamente"
            }
            */
            Swal.fire({
                text: data.message,
                icon: data.status,
                confirmButtonText: 'Aceptar'
            });
            verProductosDamaxId(idDama);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }


    /*
    ficha de dama: metodo GET: {{url}}/api/ladies/1
    te dará el json: 
    {
        "data": {
            "id": 1,
            "alias": "Florqwer",
            "numero_documento": "40888905",
            "nombres": "Viviana Belen",
            "apellidos": "Carmona",
            "fecha_nacimiento": "1997-12-19",
            "edad": 26,
            "procedencia": "Argentina",
            "telefono1": "+549 3886499060",
            "telefono2": null,
            "notas": null,
            "deuda": 0,
            "saldo": 0,
            "credit_account_id": 29,
            "debit_account_id": 28
        }
    }
    */

    const [credit_account_id, setCreditAccountId] = useState(0);
    const [debit_account_id, setDebitAccountId] = useState(0);
    const verFichaDama = (idDama) => {
        setIdDama(idDama);
        fetch(base_url+"/api/ladies/"+idDama, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            /*
            {
                "data": {
                    "id": 1,
                    "alias": "Florqwer",
                    "numero_documento": "40888905",
                    "nombres": "Viviana Belen",
                    "apellidos": "Carmona",
                    "fecha_nacimiento": "1997-12-19",
                    "edad": 26,
                    "procedencia": "Argentina",
                    "telefono1": "+549 3886499060",
                    "telefono2": null,
                    "notas": null,
                    "deuda": 0,
                    "saldo": 0,
                    "credit_account_id": 29,
                    "debit_account_id": 28
                }
            }
            */
            setAlias(data.data.alias);
            setNumeroDocumento(data.data.numero_documento);
            setNombres(data.data.nombres);
            setApellidos(data.data.apellidos);
            setFechaNacimiento(data.data.fecha_nacimiento);
            setProcedencia(data.data.procedencia);
            setTelefono1(data.data.telefono1);
            setTelefono2(data.data.telefono2);
            setNotas(data.data.notas);

            setCreditAccountId(data.data.credit_account_id);
            setDebitAccountId(data.data.debit_account_id);
        })
    }

    /*
    {{url}}/api/financialaccounts/29/transactions
    {
        "id": 1,
        "cuenta": "Banco",
        "created_at": "2024-05-18T03:42:40.000000Z",
        "descripcion": "Inicio de operaciones",
        "monto": "5000.00",
        "tipo": "D",
        "usuario": null
    }
    */

    const [arrayDetalleCuenta, setArrayDetalleCuenta] = useState([]);
    const [loadingDetalleCuenta, setLoadingDetalleCuenta] = useState(true);
    const [tituloCuenta, setTituloCuenta] = useState('');

    const verCuentaCredito = (idCuenta) => {
        setTituloCuenta('Cuenta Credito');
        setLoadingDetalleCuenta(true);
        fetch(base_url+"/api/financialaccounts/"+idCuenta+"/transactions", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setLoadingDetalleCuenta(false);
            setArrayDetalleCuenta(data.data);
        })
    }

    const verCuentaDebito = (idCuenta) => {
        setTituloCuenta('Cuenta Debito');
        setLoadingDetalleCuenta(true);
        fetch(base_url+"/api/financialaccounts/"+idCuenta+"/transactions", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setLoadingDetalleCuenta(false);
            setArrayDetalleCuenta(data.data);
        })
    }

    const toggleDama = (idDama) => {
        setLoading(true);
        //memetodo post: /api/ladies/{lady}/toggle
        fetch(base_url+"/api/ladies/"+idDama+"/toggle", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setLoading(false);
            setReloadDamas(!reloadDamas);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    /*metodo POST: /api/ladies/{lady}/creditregister
    json: {“concepto”:….
    “monto”: …
    }*/

    const [concepto, setConcepto] = useState('');
    const [montoCredito, setMontoCredito] = useState(0);
    const registrarCredito = () => {
        if(concepto != '' && montoCredito != 0){
            setLoading(true);
            let data = {
                "concepto": concepto,
                "monto": montoCredito
            };
            fetch(base_url+"/api/ladies/"+idDama+"/creditregister", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                /*
                {status: 'success', message: 'Registro de crédito realizado correctamente'}
                */
                if (data.status == 'success') {
                    setLoading(false);
                    setReloadDamas(!reloadDamas);
                    document.getElementById('closeModalCreditoForm').click();
                    Swal.fire({
                        text: data.message,
                        icon: data.status,
                        confirmButtonText: 'Aceptar'
                    });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
        else{
            Swal.fire({
                text: 'Debe llenar todos los campos',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
        }
    }


    return (
        <div>

            <table className='table table-bordered table-striped'>
                <thead>
                    <tr className='text-center'>
                        <th>Alias</th>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        {/* <th>Fecha de nacimiento</th>
                        <th>Procedencia</th> */}
                        <th>Telefono 1</th>
                        {/* <th>Telefono 2</th>
                        <th>Notas</th> */}
                        <th>Activo</th>
                        <th>Contabilidad</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan='9'>Loading...</td>
                        </tr>
                    ) : (
                        damas.map(dama => (
                            <tr key={dama.id}>
                                <td>{dama.alias}</td>
                                <td>{dama.nombres}</td>
                                <td>{dama.apellidos}</td>
                                {/* <td>{dama.fecha_nacimiento}</td>
                                <td>{dama.procedencia}</td> */}
                                <td className='text-center'>
                                    {
                                        dama.telefono1 == "" || dama.telefono1 == null? (
                                            <span>Sin telefono</span>
                                        ) : (
                                            <a href={`https://wa.me/${dama.telefono1.replace(/\s/g, '')}`} target='_blank' rel='noreferrer'>{dama.telefono1}</a>
                                        )
                                    }
                                </td>
                                {/* <td className='text-center'>
                                    {
                                        dama.telefono2 == "" || dama.telefono2 == null ? (
                                            <span>Sin telefono</span>
                                        ) : (
                                            <a href={`https://wa.me/${dama.telefono2.replace(/\s/g, '')}`} target='_blank' rel='noreferrer'>{dama.telefono2}</a>
                                        )
                                    }
                                </td>
                                <td>{dama.notas}</td> */}
                                <td className='text-center'>
                                    {
                                        dama.activo == true ? (
                                            //desactivar dama button
                                            <button className='btn btn-danger btn-xs' onClick={() => { toggleDama(dama.id); }}><i className='fa fa-times'></i> Desactivar</button>
                                        ) : (
                                            //activar dama button
                                            <button className='btn btn-success btn-xs' onClick={() => { toggleDama(dama.id); }}><i className='fa fa-check'></i> Activar</button>
                                        )
                                    }
                                </td>
                                <td className='text-center'>
                                    <button className='btn btn-primary btn-xs' title='Cuenta Credito'
                                        data-bs-toggle="modal" data-bs-target="#modalCreditoForm"
                                        onClick={() => {
                                            setIdDama(dama.id);
                                            setAliasDama(dama.alias);
                                        }}
                                    >
                                        <i className='fa fa-bill-alt'></i> Credito
                                    </button>
                                    {/* <button className='btn btn-danger btn-xs' title='Cuenta Debito'
                                        onClick={() => {
                                            verCuentaDebito(dama.debit_account_id);
                                        }}
                                    >
                                        <i className='fa fa-money'></i> Debito
                                    </button> */}
                                </td>
                                <td className='text-center'>
                                    <button className='btn btn-info btn-xs' title='Editar Productos' 
                                        data-bs-toggle="modal" data-bs-target="#modalSeleccionarProductos"
                                        onClick={() => {
                                            setIdDama(dama.id);
                                            setAliasDama(dama.alias);
                                            verProductosDamaxId(dama.id);
                                        }}
                                    >
                                        <i className='fa fa-plus'></i> Productos
                                    </button>
                                    <button className='btn btn-danger btn-xs' title='Ficha Dama' 
                                        data-bs-toggle="modal" data-bs-target="#modalFichaDama"
                                        onClick={() => {
                                            setIdDama(dama.id);
                                            setAliasDama(dama.alias);
                                            verFichaDama(dama.id);
                                        }}
                                    >
                                        <i className='fa fa-file'></i> Ficha
                                    </button>
                                    {
                                        rol_user == 'admin' ? (
                                            <button className='btn btn-warning btn-xs' title='Editar Dama' 
                                                data-bs-toggle="modal" data-bs-target="#modalFormEditarDama"
                                                onClick={() => {
                                                    dama.id != null ? setIdDama(dama.id) : setIdDama('');
                                                    dama.alias != null ? setAlias(dama.alias) : setAlias('');
                                                    dama.numero_documento != null ? setNumeroDocumento(dama.numero_documento) : setNumeroDocumento('');
                                                    dama.nombres != null ? setNombres(dama.nombres) : setNombres('');
                                                    dama.apellidos != null ? setApellidos(dama.apellidos) : setApellidos('');
                                                    dama.fecha_nacimiento != null ? setFechaNacimiento(dama.fecha_nacimiento) : setFechaNacimiento('');
                                                    dama.procedencia != null ? setProcedencia(dama.procedencia) : setProcedencia('');
                                                    dama.codigo_pais_tel1 != null ? setCodigoPaisTel1(dama.codigo_pais_tel1) : setCodigoPaisTel1('');
                                                    dama.telefono1 != null ? setTelefono1(dama.telefono1) : setTelefono1('');
                                                    dama.codigo_pais_tel2 != null ? setCodigoPaisTel2(dama.codigo_pais_tel2) : setCodigoPaisTel2('');
                                                    dama.telefono2 != null ? setTelefono2(dama.telefono2) : setTelefono2('');
                                                    dama.notas != null ? setNotas(dama.notas) : setNotas('');
                                                }}
                                            >
                                                <i className='fa fa-edit'></i> Editar
                                            </button>
                                        ) : (
                                            <span></span>
                                        )
                                    }

                                            
                                    
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

            <div className="modal fade" id="modalFormEditarDama">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">EDITAR DAMA</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id='closeModalFormEditarDama'></button>
						</div>
						<div className="modal-body">
							
                            <div className="row">

								<div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Alias</label>
                                        <input className="form-control" type="text" value={alias} onChange={(e) => setAlias(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Numero de Documento</label>
                                        <input className="form-control" type="text" value={numeroDocumento} onChange={(e) => setNumeroDocumento(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Nombres</label>
                                        <input className="form-control" type="text" value={nombres} onChange={(e) => setNombres(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Apellidos</label>
                                        <input className="form-control" type="text" value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Fecha de Nacimiento</label>
                                        <input className="form-control" type="date" value={fechaNacimiento} onChange={(e) => setFechaNacimiento(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Procedencia</label>
                                        <input className="form-control" type="text" value={procedencia} onChange={(e) => setProcedencia(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Telefono 1</label>
                                        <input className="form-control" type="text" value={telefono1} onChange={(e) => setTelefono1(e.target.value)} />
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Telefono 2</label>
                                        <input className="form-control" type="text" value={telefono2} onChange={(e) => setTelefono2(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Notas</label>
                                        <textarea className="form-control" value={notas} onChange={(e) => setNotas(e.target.value)} />
                                    </div>
                                </div>



                                

							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button className="btn btn-success btn-lg" onClick={() => { editarDama(); }} id='btnEditarDama'>Guardar</button>
							<button className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>

            <div className="modal fade" id="modalCreditoForm">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Cuenta Credito</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id='closeModalCreditoForm'></button>
						</div>
						<div className="modal-body">
							
                            <div className="row">

								<div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Concepto</label>
                                        <input className="form-control" type="text" value={concepto} onChange={(e) => setConcepto(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Monto</label>
                                        <input className="form-control" type="number" value={montoCredito} onChange={(e) => setMontoCredito(e.target.value)} />
                                    </div>
                                </div>
							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button className="btn btn-success btn-lg" onClick={() => { registrarCredito(); }} id='btnRegistrarCredito'>Guardar</button>
							<button className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>

            <div className="modal fade" id="modalSeleccionarProductos">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Seleccionar Productos</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id='closeModalSeleccionarProductos'></button>
						</div>
						<div className="modal-body">

                            <h2>Productos de {aliasDama}</h2>
							
                            <div className="table-responsive">
                                <table className='table table-bordered table-sm table-striped' style={{border: '1px solid #ccc'}}>
                                    <thead>
                                        <tr className='text-center'>
                                            <th>Producto</th>
                                            <th>Precio de Venta</th>
                                            <th>Comision</th>
                                            <th>Activo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { loadingProductosDamaxId ? (
                                            <tr>
                                                <td colSpan='4'>Loading...</td>
                                            </tr>
                                        ) : (
                                            dataProductosDamaxId.map(producto => (
                                                <tr key={producto.product_id}>
                                                    <td>{producto.producto}</td>
                                                    <td>{producto.precio_venta}</td>
                                                    <td>{producto.comision}</td>
                                                    <td className='text-center'>
                                                        {/* {producto.activo} */}
                                                        {/* <HeartSwitch
                                                            // size="lg"
                                                            inactiveTrackFillColor="#cffafe"
                                                            inactiveTrackStrokeColor="#22d3ee"
                                                            activeTrackFillColor="#06b6d4"
                                                            activeTrackStrokeColor="#0891b2"
                                                            inactiveThumbColor="#ecfeff"
                                                            activeThumbColor="#ecfeff"
                                                            checked={producto.activo}
                                                            onChange={() => { toggleProducto(producto.product_id); }}
                                                        /> */}
                                                        {
                                                            producto.activo == true ? (
                                                                <button className='btn btn-danger btn-xs' onClick={() => { toggleProducto(producto.product_id); }}><i className='fa fa-times'></i> Desactivar</button>
                                                            ) : (
                                                                <button className='btn btn-success btn-xs' onClick={() => { toggleProducto(producto.product_id); }}><i className='fa fa-check'></i> Activar</button>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>


                            <div className="row">

								<div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Producto</label>
                                        <select className="form-select" onChange={(e) => setProductoId(e.target.value)}>
                                            <option value=''>Seleccionar</option>
                                            {
                                                dataListaProductos.map(producto => (
                                                    <option key={producto.id} value={producto.id}>{producto.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
								</div>
								<div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Precio de Venta</label>
                                        <input className="form-control" type="number" value={precio_venta} onChange={(e) => setPrecioVenta(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Comision</label>
                                        <input className="form-control" type="number" value={comision} onChange={(e) => setComision(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-warning btn-sm"
                                        onClick={() => { modificarProductoDama(); }}
                                    >Modificar</button>
                                </div>

							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button className="btn btn-white btn-lg" data-bs-dismiss="modal">Cerrar</button>
						</div>
					</div>
				</div>
			</div>

            <div className="modal fade" id="modalFichaDama">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">Ficha de Dama</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id='closeModalFichaDama'></button>
						</div>
						<div className="modal-body">

                            <h1>Ficha de {alias}</h1>
							
                            <div className="row">

								<div className="col-md-6">
                                    <span> <b>Alias:</b> {alias} </span>
								</div>
                                <div className="col-md-6">
                                    <span> <b>Numero de Documento:</b> {numeroDocumento} </span>
                                </div>
                                <div className="col-md-6">
                                    <span> <b>Nombres:</b> {nombres} </span>
                                </div>
                                <div className="col-md-6">
                                    <span> <b>Apellidos:</b> {apellidos} </span>
                                </div>
                                <div className="col-md-6">
                                    <span> <b>Fecha de Nacimiento:</b> {fechaNacimiento} </span>
                                </div>
                                <div className="col-md-6">
                                    <span> <b>Procedencia:</b> {procedencia} </span>
                                </div>
                                <div className="col-md-6">
                                    <span> <b>Telefono 1:</b> {telefono1} </span>
                                </div>
                                <div className="col-md-6">
                                    <span> <b>Telefono 2:</b> {telefono2} </span>
                                </div>
                                <div className="col-md-12">
                                    <span> <b>Notas:</b> {notas} </span>
                                </div>

                                <div className="col-md-12 text-center">
                                    <button className='btn btn-success'
                                        onClick={() => {
                                            verCuentaCredito(credit_account_id);
                                        }}
                                    > <i className='fa fa-money'></i> Cuenta Credito</button>
                                    <button className='btn btn-danger'
                                        onClick={() => {
                                            verCuentaDebito(debit_account_id);
                                        }}
                                    > <i className='fa fa-money'></i> Cuenta Debito</button>
                                </div>
                                <div className="col-md-12">
                                {/* {
                                    "id": 1,
                                    "cuenta": "Banco",
                                    "created_at": "2024-05-18T03:42:40.000000Z",
                                    "descripcion": "Inicio de operaciones",
                                    "monto": "5000.00",
                                    "tipo": "D",
                                    "usuario": null
                                } */}
                                    <table className='table table-bordered table-sm table-striped' style={{border: '1px solid #ccc'}}>
                                        <thead>
                                            <tr className='text-center'>
                                                <th colSpan={4}>{tituloCuenta}</th>
                                            </tr>
                                            <tr className='text-center'>
                                                <th>Fecha</th>
                                                <th>Descripcion</th>
                                                <th>Monto</th>
                                                <th>Tipo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { loadingDetalleCuenta ? (
                                                <tr>
                                                    <td colSpan='4'>Loading...</td>
                                                </tr>
                                            ) : (
                                                arrayDetalleCuenta.length == 0 ? (
                                                    <tr>
                                                        <td colSpan='4'>No hay registros</td>
                                                    </tr>
                                                ) : (
                                                    arrayDetalleCuenta.map(transaccion => (
                                                        <tr key={transaccion.id}>
                                                            <td>{transaccion.created_at}</td>
                                                            <td>{transaccion.descripcion}</td>
                                                            <td>{transaccion.monto}</td>
                                                            <td>{transaccion.tipo}</td>
                                                        </tr>
                                                    ))
                                                )
                                            )}
                                        </tbody>
                                    </table>

                                </div>


							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button className="btn btn-success btn-lg" onClick={() => { editarDama(); }} id='btnEditarDama'>Guardar</button>
							<button className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
}

export default ListaDamas;