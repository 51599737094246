import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../components/panel/panel.jsx';
import ListaDamas from './ListaDamas.js';
import Cajas from './Cajas.js';

import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
let base_url = BASE_URL;
// let token = ACCESS_TOKEN;
let token = localStorage.getItem('token');



function Venta() {
	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><Link to="/page-option/blank">Venta</Link></li>
				<li className="breadcrumb-item"><Link to="/page-option/blank">Venta Page</Link></li>
				<li className="breadcrumb-item active">Pagina inicial</li>
			</ol>
			<h1 className="page-header">Venta <small>header small text goes here...</small></h1>
			<Panel>
				<PanelHeader>Panel Title here</PanelHeader>
				<PanelBody>
					<ListaDamas/>
				</PanelBody>
				<PanelFooter>
					<Cajas/>
				</PanelFooter>
			</Panel>
		</div>
		
	)
}

export default Venta;