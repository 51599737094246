import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import Select from 'react-select';
import ListaDamas from './listaDamas.js';

import ACCESS_TOKEN from '../../token.js';
import BASE_URL from '../../config.js';
let base_url = BASE_URL+"/api/ladies";
//let token = ACCESS_TOKEN;
let token = localStorage.getItem('token');

const optionsPaises = [
    { value: '+591', label: 'Bolivia (+591)' },
    { value: '+54', label: 'Argentina (+54)' },
    { value: '+55', label: 'Brasil (+55)' },
    { value: '+56', label: 'Chile (+56)' },
    { value: '+57', label: 'Colombia (+57)' },
    { value: '+502', label: 'Guatemala (+502)' },
    { value: '+503', label: 'El Salvador (+503)' },
    { value: '+504', label: 'Honduras (+504)' },
    { value: '+505', label: 'Nicaragua (+505)' },
    { value: '+506', label: 'Costa Rica (+506)' },
    { value: '+507', label: 'Panamá (+507)' },
    { value: '+51', label: 'Perú (+51)' },
    { value: '+52', label: 'México (+52)' },
    { value: '+598', label: 'Uruguay (+598)' },
    { value: '+595', label: 'Paraguay (+595)' },
    { value: '+593', label: 'Ecuador (+593)' },
    { value: '+58', label: 'Venezuela (+58)' },
];

function Dama() {

    const [alias, setAlias] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [procedencia, setProcedencia] = useState('');
    const [codigoPaisTel1, setCodigoPaisTel1] = useState('');
    const [telefono1, setTelefono1] = useState('');
    const [codigoPaisTel2, setCodigoPaisTel2] = useState('');
    const [telefono2, setTelefono2] = useState('');
    const [notas, setNotas] = useState('');

    const [reloadLista, setReloadLista] = useState(true);

    useEffect(() => {
        // La primera vez que se renderiza, se establece reloadLista en true para cargar ListaDamas
        if (reloadLista) {
            setReloadLista(false); // Establecer de nuevo en false para evitar un bucle infinito
        }
    }, [reloadLista]); // Este efecto se ejecuta cada vez que reloadLista cambia

    

    const registrarDama = () => {

        let data = {
            "alias": alias,
            "numero_documento": numeroDocumento,
            "nombres": nombres,
            "apellidos": apellidos,
            "fecha_nacimiento": fechaNacimiento,
            "procedencia": procedencia,
            "telefono1": codigoPaisTel1+telefono1,
            "telefono2": codigoPaisTel2+telefono2,
            "notas": notas
        };
        console.log(data);

        fetch(base_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setReloadLista(true);
            //document.querySelector('#closeModalFormNuevaDama').click();
            document.getElementById('closeModalFormNuevaDama').click();
        })
        .catch(error => {
            console.error(error);
        });
    }

    



	return (
		<div>
			<h1 className="page-header">Gestion Damas <small>Agregue, modifique y edite</small></h1>
			{/* <button className="btn btn-lg btn-primary m-3">
                <span className="d-flex align-items-center text-start">
                    <i className="fas fa-file fa-3x me-3"></i>
                    <span>
                        <span className="d-block"><b>Nueva Dama</b></span>
                        <span className="d-block fs-12px opacity-7">Formulario Registro</span>
                    </span>
                </span>
            </button> */}
			<div className="row">
                <div className="col-md-12">
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-3">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">Lista de Damas</h4>
                        </div>
                        <div className="panel-body">
                        {!reloadLista && <ListaDamas />}
                        </div>
                        <div className="hljs-wrapper text-center">
                            <button className="btn btn-primary m-3" 
                                data-bs-toggle="modal" data-bs-target="#modalFormNuevaDama"
                            >Nueva Dama</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalFormNuevaDama">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">REGISTRO DE DAMA</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id='closeModalFormNuevaDama'></button>
						</div>
						<div className="modal-body">
							
                            <div className="row">

								<div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Alias</label>
                                        <input className="form-control" type="text" value={alias} onChange={(e) => setAlias(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Numero de Documento</label>
                                        <input className="form-control" type="text" value={numeroDocumento} onChange={(e) => setNumeroDocumento(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Nombres</label>
                                        <input className="form-control" type="text" value={nombres} onChange={(e) => setNombres(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Apellidos</label>
                                        <input className="form-control" type="text" value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Fecha de Nacimiento</label>
                                        <input className="form-control" type="date" value={fechaNacimiento} onChange={(e) => setFechaNacimiento(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Procedencia</label>
                                        <input className="form-control" type="text" value={procedencia} onChange={(e) => setProcedencia(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Pais</label>
                                        <Select options={optionsPaises} classNamePrefix="react-select" 
                                        styles={{
                                            // Establece el color del texto en blanco
                                            option: (provided, state) => ({
                                              ...provided,
                                              color: 'white'
                                            }),
                                            // También puedes establecer el color del texto en el valor seleccionado
                                            singleValue: (provided, state) => ({
                                              ...provided,
                                              color: 'white'
                                            })
                                        }}
                                        onChange={(e) => setCodigoPaisTel1(e.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Telefono 1</label>
                                        <input className="form-control" type="text" value={telefono1} onChange={(e) => setTelefono1(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Pais</label>
                                        <Select options={optionsPaises} classNamePrefix="react-select" 
                                        styles={{
                                            // Establece el color del texto en blanco
                                            option: (provided, state) => ({
                                              ...provided,
                                              color: 'white'
                                            }),
                                            // También puedes establecer el color del texto en el valor seleccionado
                                            singleValue: (provided, state) => ({
                                              ...provided,
                                              color: 'white'
                                            })
                                        }}
                                        onChange={(e) => setCodigoPaisTel2(e.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Telefono 2</label>
                                        <input className="form-control" type="text" value={telefono2} onChange={(e) => setTelefono2(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Notas</label>
                                        <textarea className="form-control" value={notas} onChange={(e) => setNotas(e.target.value)} />
                                    </div>
                                </div>



                                

							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button className="btn btn-success btn-lg" onClick={registrarDama}>Registrar</button>
							<button className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>



		</div>
	)
}

export default Dama;