import React, { useState, useEffect } from 'react';
import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
import Swal from 'sweetalert2'


let base_url = BASE_URL;
// let token = ACCESS_TOKEN;
let token = localStorage.getItem('token');

/*
{
    "dataListaProductos": [
        {
            "id": 3,
            "nombre": "Cerveza paceña",
            "stock": 42,
            "precio_venta": "50.00",
            "comision": "20.00",
            "es_servicio": false
        },
        {
            "id": 4,
            "nombre": "Fernet",
            "stock": 10,
            "precio_venta": "250.00",
            "comision": "120.00",
            "es_servicio": false
        },
        {
            "id": 5,
            "nombre": "Pieza",
            "stock": 0,
            "precio_venta": "150.00",
            "comision": "120.00",
            "es_servicio": true
        }
    ]
}
*/


/* datosAgregarStock
{
    "producto_id": 3,
    "cantidad": 10,
    "costo_total": 150.0
}
*/

//const dataListaProductos = {"data":[{"id":3,"nombre":"Cerveza paceña","stock":42,"precio_venta":"50.00","comision":"20.00","es_servicio":false},{"id":4,"nombre":"Fernet","stock":10,"precio_venta":"250.00","comision":"120.00","es_servicio":false},{"id":5,"nombre":"Pieza","stock":0,"precio_venta":"150.00","comision":"120.00","es_servicio":true}]};

function ListaProductos() {
    const [producto_id, setProductoId] = useState(0);
    const [cantidad, setCantidad] = useState(0);
    const [costo_total, setCostoTotal] = useState(0);

    const [dataListaProductos, setDataListaProductos] = useState({data: []});
    const [loading, setLoading] = useState(true);

    const [reloadLista, setReloadLista] = useState(true);



    // id
    // nombre
    // stock
    // precio_venta
    // comision
    // es_servicio
    const [nombre, setNombre] = useState('');
    const [precioVenta, setPrecioVenta] = useState(0);
    const [comision, setComision] = useState(0);
    const [esServicio, setEsServicio] = useState(false);


    useEffect(() => {
        // La primera vez que se renderiza, se establece reloadLista en true para cargar ListaDamas
        if (reloadLista) {
            setReloadLista(false); // Establecer de nuevo en false para evitar un bucle infinito
        }
    }, [reloadLista]); // Este efecto se ejecuta cada vez que reloadLista cambia

    useEffect(() => {
        if (reloadLista) {
            fetch(base_url+"/api/products", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => response.json())
            .then(data => {
                setDataListaProductos(data);
                console.log(data);
                setLoading(false);
            });
        }
    }, [reloadLista]);



    const registrarNuevoStock = () => {

        document.getElementById('btnRegistrarStock').disabled = true;
        

        console.log("Producto ID: ", producto_id);
        console.log("Cantidad: ", cantidad);
        console.log("Costo Total: ", costo_total);

        fetch(base_url+"/api/lots", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                producto_id: producto_id,
                cantidad: cantidad,
                costo_total: costo_total,
            })
        })
        .then(response => response.json())
        .then(data => {
            document.getElementById('btnRegistrarStock').disabled = false;
            console.log(data);
            Swal.fire({
                text: data.message,
                icon: 'success',
                confirmButtonText: 'OK'
            });
            document.querySelector('#cerrarModalFormAgregarStock').click();
            setLoading(true);
            setReloadLista(true);
        })
        .catch(error => {
            document.getElementById('btnRegistrarStock').disabled = false;
            console.error(error);
        });
    }

    const modalFormModificarProd = (producto) => {
        console.log(producto);
        setProductoId(producto.id);
        setNombre(producto.nombre);
        setPrecioVenta(producto.precio_venta);
        setComision(producto.comision);
        setEsServicio(producto.es_servicio);

        //document.getElementById('nombre').value = producto.nombre;


    }

    const editarProducto = () => {
        console.log("Producto ID: ", producto_id);
        console.log("Nombre: ", nombre);
        console.log("Precio de Venta: ", precioVenta);
        console.log("Comision: ", comision);
        console.log("Es Servicio: ", esServicio);

        ///api/product/{idproduct}/update
        fetch(base_url+"/api/products/"+producto_id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                nombre: nombre,
                precio_venta: precioVenta,
                comision: comision,
                es_servicio: esServicio == "true"
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            document.querySelector('#closeModalFormModificarProd').click();
            setLoading(true);
            setReloadLista(true);
        })
        .catch(error => {
            console.error(error);
        });
    }


    return (
        <div>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        <th className='text-center'>Nombre</th>
                        <th className='text-center'>Stock</th>
                        <th className='text-center'>Precio de Venta</th>
                        <th className='text-center'>Comisión</th>
                        <th className='text-center'>Es Servicio</th>
                        <th className='text-center'>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan='6'>Cargando...</td>
                        </tr>
                    ) : (
                        dataListaProductos.data.length == 0 ? (
                            <tr>
                                <td colSpan='6'>No hay productos registrados</td>
                            </tr>
                        ) : (
                            dataListaProductos.data.map(producto => (
                                <tr key={producto.id}>
                                    <td>{producto.nombre}</td>
                                    <td>{producto.stock}</td>
                                    <td>{producto.precio_venta}</td>
                                    <td>{producto.comision}</td>
                                    <td>{producto.es_servicio ? 'Si' : 'No'}</td>
                                    <td className='text-center'>
                                        <button type='button' className="btn btn-primary btn-xs" data-bs-toggle="modal" data-bs-target="#modalFormAgregarStock" onClick={() => setProductoId(producto.id)}><i className='fa fa-plus'></i> Agregar Stock</button>
                                        <button type='button' className="btn btn-warning btn-xs" data-bs-toggle="modal" data-bs-target="#modalFormModificarProd" onClick={() => modalFormModificarProd(producto)}><i className='fa fa-edit'></i> Editar</button>
                                    </td>
                                </tr>
                            ))
                        )
                    )}
                </tbody>
            </table>
            <div className="modal fade" id="modalFormAgregarStock">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">REGISTRO DE STOCK</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id="cerrarModalFormAgregarStock"></button>
						</div>
						<div className="modal-body">
							
                            <div className="row">

								<div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Cantidad</label>
                                        <input className="form-control" type="number" value={cantidad} onChange={(e) => setCantidad(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Costo Total</label>
                                        <input className="form-control" type="number" value={costo_total} onChange={(e) => setCostoTotal(e.target.value)} />
                                    </div>
                                </div>


							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-success btn-lg" onClick={registrarNuevoStock} id="btnRegistrarStock">Registrar</button>
							<button type="button" className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
            <div className="modal fade" id="modalFormModificarProd">
                <div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">MODIFICAR PRODUCTO</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id='closeModalFormModificarProd'></button>
						</div>
						<div className="modal-body">
							
                            <div className="row">

								<div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Nombre</label>
                                        <input className="form-control" id="nombre" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Precio de Venta</label>
                                        <input className="form-control" type="number" value={precioVenta} onChange={(e) => setPrecioVenta(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Comision</label>
                                        <input className="form-control" type="number" value={comision} onChange={(e) => setComision(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Es Servicio</label>
                                        <select className="form-select" value={esServicio} onChange={(e) => setEsServicio(e.target.value)}>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>


							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-success btn-lg" 
                                onClick={() => { editarProducto(); }}>Editar Producto</button>
							<button type="button" className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
        </div>
        
    );
}

export default ListaProductos;