import React, { useContext, useEffect, useState } from 'react';

//import image assets/VenusLogo.png
import logoVenus from '../../assets/VenusLogo.png';

import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
import ROL_USUARIO from '../../rol_user.js';
let base_url = BASE_URL;
let token = ACCESS_TOKEN;


// /api/login
// {
//     "email": “adrian.gs8e@gmail.com",
//     "password": "admin12345"
// }

function Login() {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [estado, setEstado] = useState("");

	const handleSubmit = () => {
		document.getElementById('btnIniciarSesion').disabled = true;
		setEstado("Verificando usuario...");
		fetch(`${base_url}/api/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
			body: JSON.stringify({
				email: email,
				password: password
			})
		})
		.then(response => response.json())
		.then(data => {
			document.getElementById('btnIniciarSesion').disabled = false;
			console.log(data);
			if (data.access_token) {
				setEstado("Usuario verificado");
				localStorage.setItem('token', data.access_token);
				localStorage.setItem('email', email);

				obtenerRolUsuario(data.access_token);
				//window.location.reload();
			}
			else{
				setEstado("Correo o contraseña incorrectos");
			}
		})
		.catch((error) => {
			document.getElementById('btnIniciarSesion').disabled = false;
			console.error('Error:', error);
			setEstado("Ha ocurrido un error");
		});

	}

	//api/user

	const obtenerRolUsuario = (token) => {
		
		fetch(`${base_url}/api/user`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + token
			}
		})
		.then(response => response.json())
		.then(data => {
			console.log(data);
			if (data.role) {
				
				console.log(data.role);
				localStorage.setItem('rol_user', data.role);
				window.location.reload();
			}
			else {
				
			}
		})
		.catch((error) => {
			console.error('Error:', error);
		});
	}

	return (
		<div className="login login-v1">
			<div className="login-container">
				<div className="login-header">
					<div className="brand text-center">
						{/* <div className="d-flex align-items-center">
							<span className="logo"></span> <b>Venus</b> Admin
						</div>
						<small>Gestion de local VENUS</small> */}
						<img src={logoVenus} alt="Venus Logo" height={150} />
					</div>
					<div className="icon">
						<i className="fa fa-lock"></i>
					</div>
				</div>
				<div className="login-body">
					<div className="login-content fs-13px">
						<form>
							<div className="form-floating mb-20px">
								<input type="email" className="form-control fs-13px h-45px" id="emailAddress" placeholder="Correo" onChange={(e) => setEmail(e.target.value)} />
								<label htmlFor="emailAddress" className="d-flex align-items-center py-0">Correo</label>
							</div>
							<div className="form-floating mb-20px">
								<input type="password" className="form-control fs-13px h-45px" id="password" placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)} />
								<label htmlFor="password" className="d-flex align-items-center py-0">Contraseña</label>
							</div>
							<div className="login-buttons">
								<button type="button" className="btn h-45px btn-primary d-block w-100 btn-lg" onClick={handleSubmit} id='btnIniciarSesion'>Iniciar Sesión</button>
							</div>
							<div className="mt-20px text-center">
								<h1>{estado}</h1>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login;