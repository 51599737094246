import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2'

import BASE_URL from '../../config.js';
let base_url = BASE_URL;
let token = localStorage.getItem('token');





function Contabilidad() {

    
    const [financialaccounts, setFinancialAccounts] = useState([]);
    useEffect(() => {
        ///api/financialaccounts
        fetch(`${base_url}/api/financialaccounts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            /*data = {
        "data": [
            {
                "id": 1,
                "nombre": "Caja de Christian Gomez",
                "tipo": "A",
                "comportamiento": "A",
                "saldo": "2000.00",
                "prenda": null,
                "notas": null
            },
            {
                "id": 2,
                "nombre": "Caja de Adrian Gomez",
                "tipo": "A",
                "comportamiento": "A",
                "saldo": "0.00",
                "prenda": null,
                "notas": null
            },
            */
            setFinancialAccounts(data.data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }, []);


    const [cuenta_debitar_id, setCuentaDebitarId] = useState(0);
    const [cuenta_acreditar_id, setCuentaAcreditarId] = useState(0);
    const [descripcion, setDescripcion] = useState('');
    const [monto, setMonto] = useState(0);

    const registrarTransaccion = () => {
        document.getElementById('btnRegistrarTransaccion').disabled = true;
        /*
        metodo POST: /api/accountingentries   , json:
{
    "cuenta_debitar_id": 1,
    "cuenta_acreditar_id": 4,
    "descripcion": "Entrega de dinero para compra de mercadería",
    "monto": 2000.0
}*/

        let datos = {
            cuenta_debitar_id: cuenta_debitar_id,
            cuenta_acreditar_id: cuenta_acreditar_id,
            descripcion: descripcion,
            monto: monto
        };
        console.log(datos);

        if(cuenta_debitar_id == 0 || cuenta_acreditar_id == 0 || descripcion == '' || monto == 0){
            document.getElementById('btnRegistrarTransaccion').disabled = false;
            Swal.fire({
                title: 'Error',
                text: 'Todos los campos son requeridos',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        fetch(`${base_url}/api/accountingentries`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(datos)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            document.getElementById('btnRegistrarTransaccion').disabled = false;
            if (data.message) {
                Swal.fire({
                    title: 'Transaccion registrada',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            }
        })
        .catch(error => {
            document.getElementById('btnRegistrarTransaccion').disabled = false;
            console.error('Error:', error);
        });
    }

    return (
        <div>

            <div className="row">
                <div className="col-md-12">
                    <div className="panel panel-inverse" data-sortable-id="form-stuff-3">
                        <div className="panel-heading ui-sortable-handle">
                            <h4 className="panel-title">Debita Cuentas</h4>
                        </div>
                        <div className="panel-body row">
                            
                            <div className="col-md-6">
								<label className="form-label col-form-label">Cuenta a Acreditar</label>
								<div className="input-group">
									<select className="form-select form-select-lg" onChange={(e) => setCuentaAcreditarId(e.target.value)}>
                                        <option value="0">Seleccione una cuenta</option>
										{
                                            financialaccounts.map((financialaccount) => {
                                                return (
                                                    <option key={financialaccount.id} value={financialaccount.id}>{financialaccount.nombre}</option>
                                                );
                                            }
                                            )
										}
									</select>
								</div>
                            </div>
                            <div className="col-md-6">
								<label className="form-label col-form-label">Cuenta a Debitar</label>
                                <div className="input-group">
                                    <select className="form-select form-select-lg" onChange={(e) => setCuentaDebitarId(e.target.value)}>
                                        <option value="0">Seleccione una cuenta</option>
                                        {
                                            financialaccounts.map((financialaccount) => {
                                                return (
                                                    <option key={financialaccount.id} value={financialaccount.id}>{financialaccount.nombre}</option>
                                                );
                                            }
                                            )
                                        }
                                    </select>
                                </div>
							</div>
                            <div className="col-md-6">
                                <label className="form-label col-form-label">Descripcion</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" onChange={(e) => setDescripcion(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label col-form-label">Monto</label>
                                <div className="input-group">
                                    <input type="number" className="form-control" onChange={(e) => setMonto(e.target.value)} />
                                </div>
                            </div>

                        </div>
                        <div className="hljs-wrapper text-center">
                            <button className="btn btn-primary m-3" onClick={() => { registrarTransaccion() }} id='btnRegistrarTransaccion'>Registrar Transaccion</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Contabilidad;