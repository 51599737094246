import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Highlight from 'react-highlight';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
let base_url = BASE_URL;
// let token = ACCESS_TOKEN;
let token = localStorage.getItem('token');

//let jsonDatos = '[{"id":2,"dama":"Daniela","products":[{"id":3,"nombre":"Cerveza paceña","es_servicio":false,"precio_venta":"50.00","comision":"20.00"},{"id":4,"nombre":"Fernet","es_servicio":false,"precio_venta":"250.00","comision":"120.00"},{"id":5,"nombre":"Pieza","es_servicio":true,"precio_venta":"150.00","comision":"120.00"}]},{"id":3,"dama":"Karen","products":[{"id":3,"nombre":"Cerveza paceña","es_servicio":false,"precio_venta":"50.00","comision":"20.00"},{"id":4,"nombre":"Fernet","es_servicio":false,"precio_venta":"250.00","comision":"120.00"},{"id":5,"nombre":"Pieza","es_servicio":true,"precio_venta":"150.00","comision":"120.00"}]},{"id":5,"dama":"Leila","products":[{"id":3,"nombre":"Cerveza paceña","es_servicio":false,"precio_venta":"50.00","comision":"20.00"},{"id":4,"nombre":"Fernet","es_servicio":false,"precio_venta":"250.00","comision":"120.00"},{"id":5,"nombre":"Pieza","es_servicio":true,"precio_venta":"150.00","comision":"120.00"}]},{"id":4,"dama":"Lilith","products":[{"id":3,"nombre":"Cerveza paceña","es_servicio":false,"precio_venta":"50.00","comision":"20.00"},{"id":4,"nombre":"Fernet","es_servicio":false,"precio_venta":"250.00","comision":"120.00"},{"id":5,"nombre":"Pieza","es_servicio":true,"precio_venta":"150.00","comision":"120.00"}]},{"id":1,"dama":"Natasha","products":[{"id":3,"nombre":"Cerveza paceña","es_servicio":false,"precio_venta":"50.00","comision":"20.00"},{"id":4,"nombre":"Fernet","es_servicio":false,"precio_venta":"250.00","comision":"120.00"},{"id":5,"nombre":"Pieza","es_servicio":true,"precio_venta":"150.00","comision":"120.00"}]}]';

function ListaDamas() {
	const [datos, setDatos] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		//setDatos(JSON.parse(jsonDatos));
		fetch(`${base_url}/api/sales/showcase`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		})
		.then(response => response.json())
		.then(data => {
			if(data.message === 'Unauthenticated.') {
				localStorage.removeItem('token');
				window.location.href = '/login';
			}
			else{
				console.log(data);
				setDatos(data);
				setLoading(false);
			}
		})
		.catch(error => {
			console.error('Error:', error);
		});
	}, []);

	const [paymentMethods, setPaymentMethods] = useState([]);
	useEffect(() => {
		fetch(`${base_url}/api/paymentmethods`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		})
		.then(response => response.json())
		.then(data => {
			console.log(data);
			setPaymentMethods(data.data);
		})
		.catch(error => {
			console.error('Error:', error);
		});
	}, []);





	const [showModal, setShowModal] = useState(false);
	const [damaId, setDamaId] = useState(0);
	const [damaNombre, setDamaNombre] = useState('');
	const [productoId, setProductoId] = useState(0);
	const [productoNombre, setProductoNombre] = useState('');
	const [esServicio, setEsServicio] = useState(false);
	const [precioVenta, setPrecioVenta] = useState(0);
	const [comision, setComision] = useState(0);
	const [stock, setStock] = useState(0);
	const [metodopago_id, setMetodoPagoId] = useState(1);
	const [ref, setRef] = useState('');
	const [comision_local, setComisionLocal] = useState(0);


	const [cantidad, setCantidad] = useState(1);
	const [totalVenta, setTotalVenta] = useState(0);


	// Método para abrir el modal
	const openModal = () => setShowModal(true);

	// Método para cerrar el modal
	const closeModal = () => setShowModal(false);

	const openModalVenta = (damaId, damaNombre, productoId, productoNombre, esServicio, precioVenta, comision, stock) => {

		setDamaId(damaId);
		setDamaNombre(damaNombre);
		setProductoId(productoId);
		setProductoNombre(productoNombre);
		setEsServicio(esServicio);
		setPrecioVenta(precioVenta);
		setComision(comision);
		setStock(stock);
		setMetodoPagoId(1);
		setCantidad(1);
		
		setTotalVenta(cantidad * precioVenta);

		setComisionLocal(precioVenta - comision);

		openModal();
		
    };

	const disminuirCantidad = () => {
		// Verifica que la cantidad sea mayor que 0 antes de disminuir
		if (cantidad > 0) {
			// Disminuye la cantidad en 1
			setCantidad(cantidad - 1);
			// Calcula el nuevo total de venta
			let total = (cantidad - 1) * precioVenta;
			setTotalVenta(total);
		}
	}
	  
	const aumentarCantidad = () => {
		// Aumenta la cantidad en 1
		setCantidad(cantidad + 1);
		// Calcula el nuevo total de venta
		let total = (cantidad + 1) * precioVenta;
		setTotalVenta(total);
	}

	const precioVentaChange = (e) => {
		// Verifica que el precio de venta sea mayor que 0
		if (e.target.value > 0) {
			// Cambia el precio de venta
			setPrecioVenta(e.target.value);
			// Calcula el nuevo total de venta
			let total = cantidad * e.target.value;
			setTotalVenta(total);
		}
	}


	const registrarVenta = () => {
		document.getElementById('btnRegistrarVenta').disabled = true;
		/*
		BASE_URL/api/sales
		si es servicio = false
		{
			"dama_id": 10,
			"producto_id": 3,
			"cantidad": 2,
			"metodopago_id": 2,
			"ref": "7238"
		}
		si es servicio = true
		{
			"dama_id": 10,
			"producto_id": 5,
			"cantidad": 1,
			"metodopago_id": 3,
			"ref": "8372"
			"precio_venta": 150,
			"comision_local": 50,
		}
		*/
		let jsonDatos = {
			dama_id: damaId,
			producto_id: productoId,
			cantidad: cantidad,
			metodopago_id: metodopago_id,
			ref: ref
		};
		if (esServicio) {
			jsonDatos.precio_venta = precioVenta;
			jsonDatos.comision_local = comision_local;
		}
		console.log(jsonDatos);
		fetch(`${base_url}/api/sales`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(jsonDatos)
		})
		.then(response => response.json())
		.then(data => {
			document.getElementById('btnRegistrarVenta').disabled = false;
			console.log(data);
			/*
			{
				"message": "success",
				"data": {
					"cantidad": 2,
					"precio_venta": "50.00",
					"total_venta": 100,
					"comision": "20.00",
					"total_comision": 40,
					"lady_id": 1,
					"product_id": 1,
					"user_id": 1,
					"payment_method_id": 1,
					"updated_at": "2024-04-22T03:44:23.000000Z",
					"created_at": "2024-04-22T03:44:23.000000Z",
				 "id": 3
			    }
			}
			*/
			if (data.message === 'success') {
				Swal.fire({
					title: 'Venta registrada',
					text: `Venta registrada con éxito. Total de venta: ${data.data.total_venta} Bs.`,
					icon: 'success',
					confirmButtonText: 'Imprimir comprobante'
				}).then((result) => {
					if (result.isConfirmed) {
						imprimirRecibo(data.data);
					}
				});
				document.getElementById('cerrarModalVenta').click();

			}
			else {
				Swal.fire({
					title: 'Error al registrar la venta',
					text: 'Error: ' + data.message,
					icon: 'error',
					confirmButtonText: 'Aceptar'
				});
			}
		})
	}

	const imprimirRecibo = (data) => {
		// Imprimir recibo
		let contenido = `
		<!DOCTYPE html>
		<html lang="es">
		<head>
			<meta charset="UTF-8">
			<meta http-equiv="X-UA-Compatible" content="IE=edge">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
			<title>Recibo de Venta</title>
			<style>
				* {
					font-family: Arial, sans-serif;
				}
				.container {
					width: 100%;
					max-width: 800px;
					margin: 0 auto;
					padding: 10px;
				}
				.text-center {
					text-align: center;
				}
				.text-right {
					text-align: right;
				}
				.text-left {
					text-align: left;
				}
				.bold {
					font-weight: bold;
				}
				.italic {
					font-style: italic;
				}
				.underline {
					text-decoration: underline;
				}
			</style>
		</head>
		<body>
			<div class="container">
				<h2 class="text-center">Recibo de Venta</h2>
				<span class="text-left"><b>Fecha:</b> ${data.created_at}</span>
				<span class="text-left"><b>Dama:</b> ${damaNombre}</span><br>
				<span class="text-left"><b>Producto:</b> ${productoNombre}</span><br>
				<span class="text-left"><b>Cantidad:</b> ${data.cantidad}</span><br>
				<span class="text-left"><b>Precio de Venta:</b> ${data.precio_venta} Bs.</span><br>
				<span class="text-left"><b>Total de Venta:</b> ${data.total_venta} Bs.</span><br>
				<span class="text-left"><b>Comisión:</b> ${data.comision} Bs.</span><br>
				<span class="text-left"><b>Total de Comisión:</b> ${data.total_comision} Bs.</span><br>
				<span class="text-left"><b>Método de Pago:</b> ${data.payment_method_id}</span><br>
				<span class="text-left"><b>Referencia:</b> ${data.ref}</span><br>
			</div>
		</body>
		</html>
		`;
		let ventana = window.open('', 'PRINT', 'height=400,width=600');
		ventana.document.write(contenido);
		ventana.document.close();
		ventana.print();
		ventana.close();
	}
	


    
    return (
        <div>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr className='text-center'>
                        <th>Nombres</th>
                        <th>Productos</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
					{loading ? (
						<tr>
							<td colSpan='2'>Loading...</td>
						</tr>
					) : (
						datos.map(dama => (
							<tr key={dama.id}>
								<td className='align-middle' style={stylesListaDamas.textoNombreDamas}>{dama.dama}</td>
								<td className='align-middle'>
									{dama.products.map((producto) => (
										<button
											className={`btn ${producto.es_servicio ? 'btn-warning' : 'btn-primary'} m-1`}
											key={producto.id}
											onClick={() => openModalVenta(dama.id, dama.dama, producto.id, producto.nombre, producto.es_servicio, producto.precio_venta, producto.comision, producto.stock)}
											data-bs-toggle="modal" data-bs-target="#modalVenta"
										>
											<span style={stylesListaDamas.textoNombreProducto}>{producto.nombre}</span>
											<br/>
											{producto.precio_venta} Bs.
										</button>
									))}
								</td>
							</tr>
						))
					)}
                </tbody>
            </table>

			

			<div className="modal fade" id="modalVenta">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">REGISTRO DE VENTA</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id="cerrarModalVenta"></button>
						</div>
						<div className="modal-body">
							<p style={stylesListaDamas.textoModalInfo} className='text-danger'>
								<b>Dama: </b><span>{damaNombre}</span>
							</p>
							<p style={stylesListaDamas.textoModalInfo} className='text-primary'>
								<b>{esServicio ? "Servicio" : "Producto"}: </b><span>{productoNombre}</span>
							</p>
							{
								esServicio ? (
									<div className='row'>
										<div className='col-md-6'>
											<label className="form-label col-form-label text-success" style={{fontSize: "1.5em"}}>Precio de Venta</label>
											<div className="input-group">
												<input type="number" className="form-control text-center form-control-lg" value={precioVenta} onChange={(e) => precioVentaChange(e)}/>
											</div>
										</div>
										<div className='col-md-6'>
											<label className="form-label col-form-label text-success" style={{fontSize: "1.5em"}}>Comision Local</label>
											<div className="input-group">
												<input type="number" className="form-control text-center form-control-lg" value={comision_local} onChange={(e) => setComisionLocal(e.target.value)}/>
											</div>
										</div>
									</div>
								) : (
									<div>
										<p style={stylesListaDamas.textoModalInfo} className='text-success'>
											<b>Precio de Venta: </b><span>{precioVenta} Bs.</span>
										</p>
										<p className='text-warning'>
											<b>Comision de la dama: </b><span>{comision} Bs.</span>
										</p>
									</div>
								)
							}
							{
								/*esServicio ? (
									<div className='row'>
										<div className='col-md-6'>
											<label className="form-label col-form-label col-md-3 text-success">Comision Local</label>
											<div className="input-group">
												<input type="text" className="form-control text-center form-control-lg" value={comision_local} onChange={(e) => setComisionLocal(e.target.value)}/>
											</div>
										</div>
									</div>
								) : (
									<p className='text-warning'>
										<b>Comision de la dama: </b><span>{comision} Bs.</span>
									</p>
								)*/
							}
							
							{
								esServicio ? (
									<div>
										<label className="form-label col-form-label col-md-3">Cantidad: {cantidad}</label>
									</div>
								) : (
									<div className='row'>
										<div className='col-md-4 text-center'>
											<button type="button" className="btn btn-danger btn-lg" onClick={disminuirCantidad}>
												<span className=""><i className='fas fa-minus'></i></span>
											</button>
										</div>
										<div className='col-md-4 text-center' style={{fontSize: "2.5em", fontWeight: "bold", color: "white"}}>
											<span className="form-label col-form-label col-md-3 text-white">{cantidad}</span>
										</div>
										<div className='col-md-4 text-center'>
											<button type="button" className="btn btn-danger btn-lg" onClick={aumentarCantidad}>
												<span className=""><i className='fas fa-plus'></i></span>
											</button>
										</div>
									</div>
								)
							}
							
							
							<div>
								<label className="form-label text-white mt-2" style={{fontSize: "3em"}}
								><b>Total:</b>  {totalVenta}</label>
							</div>

							<div>
								<label className="form-label col-form-label col-md-3">Metodo de Pago:</label>
								<div className="input-group">
									<select className="form-select form-select-lg" onChange={(e) => setMetodoPagoId(e.target.value)}>
										{
											paymentMethods.map((paymentMethod) => (
												<option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.nombre}</option>
											))
										}
									</select>
								</div>
							</div>
							<div>
								<label className="form-label col-form-label col-md-3">Referencia:</label>
								<div className="input-group">
									<input type="text" className="form-control form-control-lg" onChange={(e) => setRef(e.target.value)}/>
								</div>
							</div>
							
							
								
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger btn-lg" onClick={() => registrarVenta()} id='btnRegistrarVenta'>Registrar Venta</button>
							<button type="button" className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>


        </div>

		
    );
}

const stylesListaDamas = {
	textoNombreDamas: {
		fontSize: "2em",
		fontWeight: "bold",
	},
	textoNombreProducto:{
		fontSize: "1.5em",
		fontWeight: "bold",
	},
	textoModalInfo:{
		fontSize: "1.5em",
	}

}

export default ListaDamas;