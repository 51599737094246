import React, { useState, useEffect } from 'react';
import BASE_URL from '../../config.js';
import ACCESS_TOKEN from '../../token.js';
import Swal from 'sweetalert2';
let base_url = BASE_URL;
let token = localStorage.getItem('token');

function ListaCajas() {
    const [dataListaCajas, setDataListaCajas] = useState({data: []});
    const [loading, setLoading] = useState(true);

    const [loadingDetalleVenta, setLoadingDetalleVenta] = useState(true);

    const [reloadLista, setReloadLista] = useState(true);

    const [arrayListaVentas, setArrayListaVentas] = useState([]);
    const [totalVentas, setTotalVentas] = useState(0);

    useEffect(() => {
        // La primera vez que se renderiza, se establece reloadLista en true para cargar ListaDamas
        if (reloadLista) {
            setReloadLista(false); // Establecer de nuevo en false para evitar un bucle infinito
        }
    }, [reloadLista]); // Este efecto se ejecuta cada vez que reloadLista cambia

    useEffect(() => {
        if (reloadLista) {
            // Volver a cargar los datos de la lista
            fetch(base_url+"/api/cashcounts/pendientes", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => response.json())
            .then(data => {
                setDataListaCajas(data);
                console.log(data);
                setLoading(false);
                setReloadLista(false); // Establecer reloadLista en false después de cargar la lista
            });
        }
    }, [reloadLista]);

    const modalReporteVentas = (caja_id) => {
        ///api/cashcounts/"+caja_id+"/sales
        setLoadingDetalleVenta(true);
        fetch(base_url+"/api/cashcounts/"+caja_id+"/sales", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setLoadingDetalleVenta(false);
            setArrayListaVentas(data.data);
            let total = 0;
            data.data.forEach(venta => {
                total += parseFloat(venta.precio);
            });
            setTotalVentas(total);
        });

    }

    const recogerCaja = (caja_id) => {

        Swal.fire({
            title: 'Caja',
            text: '¿Está seguro de recoger la caja?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                recogerCajaConfirmado(caja_id);
            }
        });
        
    }

    const recogerCajaConfirmado = (caja_id) => {
        fetch(base_url+"/api/cashcounts/"+caja_id+"/recoger", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setReloadLista(true);
            Swal.fire({
                title: 'Caja',
                text: data.message,
                icon: data.status,
                confirmButtonText: 'OK'
            });
        });
    }


    return (
        <div>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Usuario</th>
                        <th>Monto Apertura</th>
                        <th>Monto Cierre</th>
                        <th>Monto Cierre Efectivo</th>
                        <th>Monto Cierre Tarjeta QR</th>
                        <th>Monto Cierre Otros</th>
                        <th>Observaciones</th>
                        <th>Abierto en</th>
                        <th>Cerrado en</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan='12'>Cargando...</td>
                        </tr>
                    ) : (
                        dataListaCajas.data.length == 0 ? (
                            <tr>
                                <td colSpan='12'>No hay cajas pendientes</td>
                            </tr>
                        ) : (
                            dataListaCajas.data.map(caja => (
                                <tr key={caja.id}>
                                    <td>{caja.id}</td>
                                    <td>{caja.usuario}</td>
                                    <td>{caja.monto_apertura}</td>
                                    <td>{caja.monto_cierre}</td>
                                    <td>{caja.monto_cierre_efectivo}</td>
                                    <td>{caja.monto_cierre_tarjeta_qr}</td>
                                    <td>{caja.monto_cierre_otros}</td>
                                    <td>{caja.observaciones}</td>
                                    <td>{caja.abierto_en}</td>
                                    <td>{caja.cerrado_en}</td>
                                    <td className='text-center'>
                                        <button className="btn btn-success btn-sm" 
                                            data-bs-toggle="modal" data-bs-target="#modalReporteVentas"
                                            onClick={() => modalReporteVentas(caja.id)}
                                            ><i className='fas fa-file'></i> Detalle
                                        </button>

                                        <button className="btn btn-danger btn-sm"
                                            onClick={() => recogerCaja(caja.id)}
                                            ><i className='fas fa-hand-holding-usd'></i> Recoger</button>
                                    </td>
                                </tr>
                            ))
                        )
                    )}
                </tbody>
            </table>

            <div className="modal fade" id="modalReporteVentas">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">REPORTE DE VENTAS - CAJAS PENDIENTES</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id="cerrarModalReporteVentas"></button>
						</div>
						<div className="modal-body">
							
                            <table className="table table-bordered table-striped table-hover" style={{border: '1px solid #77878E'}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Producto</th>
                                        <th>Dama</th>
                                        <th>Cantidad</th>
                                        <th>Precio</th>
                                        <th>Comisión</th>
                                        <th>Método de Pago</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {loadingDetalleVenta ? (
                                        <tr>
                                            <td colSpan='9'>Cargando...</td>
                                        </tr>
                                    ) : (
                                        arrayListaVentas.length == 0 ? (
                                            <tr>
                                                <td colSpan='9'>No hay ventas registradas</td>
                                            </tr>
                                        ) : (
                                            arrayListaVentas.map((venta, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{venta.id}</td>
                                                        <td>{venta.fecha}</td>
                                                        <td>{venta.hora}</td>
                                                        <td>{venta.producto}</td>
                                                        <td>{venta.dama}</td>
                                                        <td>{venta.cantidad}</td>
                                                        <td>{venta.precio}</td>
                                                        <td>{venta.comision}</td>
                                                        <td>{venta.metodo_pago}</td>
                                                    </tr>
                                                )
                                            })
                                        )

                                    )
                                }
                                </tbody>
                            </table>

                            <div className="row">
                                <div className="col-md-6">
                                    <h2><strong>Total de ventas: </strong> {totalVentas} Bs.</h2>
                                </div>
                            </div>
						</div>
						<div className="modal-footer">
							<button className="btn btn-danger btn-lg" data-bs-dismiss="modal">Cerrar</button>
						</div>
					</div>
				</div>
			</div>

            {/* <div className="modal fade" id="modalFormAgregarStock">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">REGISTRO DE STOCK</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" id="cerrarModalFormAgregarStock"></button>
						</div>
						<div className="modal-body">
							
                            <div className="row">

								<div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Cantidad</label>
                                        <input className="form-control" type="number" value={cantidad} onChange={(e) => setCantidad(e.target.value)} />
                                    </div>
								</div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Costo Total</label>
                                        <input className="form-control" type="number" value={costo_total} onChange={(e) => setCostoTotal(e.target.value)} />
                                    </div>
                                </div>


							</div>
                            
								
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-success btn-lg" onClick={registrarNuevoStock}>Registrar</button>
							<button type="button" className="btn btn-white btn-lg" data-bs-dismiss="modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div> */}
        </div>
        
    );
}

export default ListaCajas;